.otpFormControlInput {
  width: 80%;
  padding: 0.8em 1em;
  text-align: center;
}

.inputColumn {
  padding: 0rem;
  margin: 0rem;
}

#verifyTitle {
  text-transform: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.leftBackground {
  background-color: #0B6F9D;
}

.heding {
  width: 120px;
  height: 48px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  margin-left: 10px;
  letter-spacing: -0.02em;
  color: #000000;
}

.heding-1 {
  width: 207px;
  height: 34px;
  left: 850px;
  top: 200px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;

  letter-spacing: -0.02em;
  color: #566A7F;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 174px;
  height: 45px;
  border: none;
  background: #0B6F9D;
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #FFFFFF;
}

.emptyBox {
  box-sizing: border-box;

  width: 50px;
  height: 50px;

  border: 1px solid #0671E0;
  box-shadow: 0px 4px 4px rgba(6, 113, 224, 0.2);
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}