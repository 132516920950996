.pull-right {
    float: right !important;
    position: absolute;
}

.fa-minus {
    color: #1B7189;
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
}

.fa-plus {
    color: #1B7189;
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
}

.list-group-item {
    display: block;
    padding: 10px 15px;
    margin-bottom: 1px;
    color: black;
    background-color: #fff;
    border: 1px solid #ddd;
}

.list-group-item:hover {
    color: black;
    background-color: #fff;
}

element.style {
    width: 25%;
    height: 256px;
}

.list-group-item:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.list-group>li {
    list-style: none;
    padding: 0.8em;
}

.updateButton {
    width: 110px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: rgb(11, 111, 157);
}

.cancelButton {
    width: 110px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: rgb(113, 115, 115);
}

.checkbox {
    width: 15px;
    height: 15px;
}