#topCategoriesCard {
    border-radius: 5px;
    min-height: 100%;
    width: 18%;
    /* flex: 30% 18% 18%; */
    /* padding: 0.2em 0em; */
    margin: 1em;

}

.droppable {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 600px) {
    #topCategoriesCard {
        border-radius: 5px;
        min-height: 100%;
        width: 100%;
    }
}

#cardTitle {
    color: black;
    text-transform: capitalize;
    margin: 0.2em;
    margin-top: 0.5em;
}

.card-info.category {
    margin: auto;

}

.card-icon.category {
    /* margin: auto; */
    padding: 0em;
    margin: 0em;
}

.card-body.category {
    padding: 1rem
}

#categoryCardRow {
    padding: 0.5em 0em 0.5em 0.5em;
}

#MoveIconBtn {
    margin: 0.3em;
    float: right;
    border-radius: 14%;
    background: #808B96;
    height: 30px;
    color: #fff;
    width: 43px;
}

/* {
    float: right;
        background: #808B96;
        color: #fff;
        margin: 0.2em;
        width: 45px;
        height: 29px;
        border-radius: 5px;
} */

.threedots:after {
    content: "\2807";
    font-size: 26px;
    color: rgb(74, 71, 71);
}