.tabSetting {
  padding: 8px;
  /* height: 40px; */
  width: 160px;
  margin-right: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  color: black;
  background-color: #cad2d3;
}

.settingTab .react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 0px;
  padding: 0;
}

.settingTab .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  background: #005476;
  margin: 0em 0.5em 0em 0em;
  color: black !important;
  border-radius: 5px 5px 0px 0px;
}

.settingTab .react-tabs__tab--selected {
  background-color: #005476 !important;
  border-color: #aaa;
  color: #fff;
  border-radius: 10px 10px 0 0;
  border: none;
  pointer-events: none;
}

.settingTab .react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 0px;
  padding: 0;
  display: grid !important;
}

.hoverTab:hover {
  background-color: #005476;
  color: #fff;
}