#notificationFormLabel {
    text-align: left;
}

#notificationMessage {
    width: 100%;
    padding: 10px;
    margin: 1em 0.9em 0em 0.9em;
    height: 137px !important;
    border: 1px solid lightgray;
    border-radius: 4px;
}

.labelColumn {
    text-align: right;
    padding-right: 1rem;
    color: #000;
    font-weight: 600;
    margin: auto;
}


#submitBtn {
    float: left;
    height: 100%;
    align-items: center;
}

.copyIcon {
    height: 0.8em !important;
    cursor: pointer;
}

.listColumn {
    list-style: none;
}

.emailBody {
    padding: 2em;
    border: 1px solid lightgrey;
}

.formCard {
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cardHeaderTitle {
    font-weight: 600;
    font-size: 15px;
    color: #0099f1;
    margin-left: 0.3em;
}

.cardHeader {
    padding: 0.3em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.sponsorDetailColumn {
    position: relative;
    padding: 5px;
}

.ptsIcon {
    font-size: large;
    color: #000;
}

.sponsorDetailFooter {
    text-align: center;
    width: 100%;
    margin-top: 2em;
    padding: 0em 0em 1em;
}

/* img {
    width: 0% !important;
    overflow: hidden;
} */