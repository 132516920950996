.signupLink {
  text-align: left;
}

.authentication-inner {
  align-items: initial;
}

.tenantList {
  text-align: left;
  list-style: none;
}

.siginButton {
  background-color: #0b6f9d;
  width: 400px;
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  align-items: center;
  border: none;
  border-radius: 5px;
}

.adminSigninHeding {
  /* width: 106px; */
  height: 48px;
  margin-left: -10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  /* identical to box height, or 48px */
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}

.logoBackground {
  border-radius: 70%;
  background-color: #fff;
  width: 300px;
  height: 300px;
  /* margin-top: -150px; */
  /* margin-left: 220px; */
  align-items: center;
}

.logoHeding {
  margin-top: 40px;
  margin-left: -22px;
  /* text-transform: uppercase; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.logoImage {
  /* width: 90%; */
  height: 220px;
  margin-top: 45px;
  /* margin-left: 1px; */
}

@media (max-width: 950px) {
  .logoBackground {
    border-radius: 50%;
    background-color: #fff;
    width: 180px;
    height: 180px;
    /* margin-top: -150px; */
    margin-left: 60px;
    align-items: center;
  }

  .logoImage {
    /* width: 100%; */
    height: 120px;
    margin-top: 30px;
    /* margin-left: 1px; */
  }

  .logoHeding {
    margin-top: 30px;
    margin-left: 8px;
    font-size: 45px;
  }
}
