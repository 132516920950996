.pagination {
    margin: 0;
    font-size: small;
}

.page-item {
    cursor: pointer;
}

.assetUID {
    cursor: pointer;
}

.gsearchButton {
    /* background-color: rgb(242, 109, 33); */
    background-color: #0B6F9D;
    color: #fff;
    border: "none";
    height: 34.5px;
    width: 60px;
    border-radius: 0 5px 5px 0;
    font-size: 15px;
}

#gsearch {
    width: 240px;
    height: 33px;
    outline: none;
}

.pageSize {
    padding-top: 12px;
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.totalResults {
    font-size: 18px;
    font-weight: 600;
    color: black;
}

.pagenavigation {
    width: 80px;
    padding: 10px;
    color: black;
    font-size: 15px;
    font-weight: 600;
}

.assetTrakingThead th {
    background: #0B6F9D;
    color: #fff !important;
    font-weight: bold;
}

@media (max-width: 600px) {
    .gsearchButton {
        width: 35px;
        height: 34.5px;
    }

    #gsearch {
        width: 200px;
        height: 33px;
    }

    .pagination {
        flex-wrap: wrap
    }
}