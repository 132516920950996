.customerCard {
    height: 590px;
}
.customerCard-1 {
    height: 590px;
    margin: 0;
}
.custBut{
    margin-top: 0;
}
.viewCustCard{
    height: 420px;
}
@media only screen and (min-width: 200px) and (max-width: 900px) {
    .customerCard {
        height: 100%;
    }
    .customerCard-1 {
        height: 100%;
        margin-top: 15px;
    }
    .custBut{
        margin-top: 20px;
    }
  } 