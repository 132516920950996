.Tab {
  padding-left: 40px;
  padding-right: 40px;
}

.editCardSize {
  border: 1px solid transparent;
  height: 530px;
}

.viewCardSize {
  border: 1px solid transparent;
  height: 530px;
  margin-top: 0;
}

.uploadButton {
  background-color: #0B6F9D;
  border: none;
  padding: 13.5px;
  color: #ffff;
}

#inputGroupFile {
  border: "1px solid gray",

}

#inputFile {
  height: 18px !important;
}

@media only screen and (min-width: 200px) and (max-width: 885px) {
  .viewCardSize {
    height: 100%;
    border: 1px solid transparent;
  }

  .editCardSize {
    border: 1px solid transparent;
    height: 100%;
  }
}

#OrganizationCol {
  text-align: left;
  margin: auto;
}

#organizationFormCard {
  position: relative !important;
  height: 100% !important;
  padding: 1em;
}

#organizationFormCard {
  position: relative !important;
  height: 100% !important;
  padding: 1em;
}

.selectFile {
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  width: 148px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  border: none;
}

.logoUploadeBtn {
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  width: 180px;
  height: 40px;
  color: #ffff;
  background: #0B6F9D;
  border-radius: 5px;
  text-align: center;
  border: none;
}

.dropzone {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgb(117, 112, 112);
  padding: 20px;
}

/* .backgroundImage {
  background-image: url("../../../assets/images/Asset-Tracking\ Logo.png");
  height: 50%;
  width: 50%;
} */