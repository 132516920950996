.startDateTimeInput {
    width: 100%;
    height: 42px;
    padding: 10px;
}

.endDateTimeInput {
    width: 100%;
    height: 42px;
    padding: 10px;
}

.assetCycleTabList {
    margin-left: -31px;
}

.assetCycleTab {
    padding: 8px;
    height: 100%;
    width: 260px;
    margin-right: 10px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    color: black;
    background-color: #cad2d3;
}

.assetCycleTabs .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 0px;
    padding: 0;
}

.assetCycleTabs .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    background: #005476;
    margin: 0em 0.5em 0em 0em;
    color: black !important;
    border-radius: 5px 5px 0px 0px;
}

.assetCycleTabs .react-tabs__tab--selected {
    background: #005476;

    border-color: #aaa;
    color: #fff;
    border-radius: 10px 10px 0 0;
    border: none;
    pointer-events: none;
}

.assetCycleTabs .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 0px;
    padding: 0;
    display: grid !important;
}

.hoverTab:hover {
    background-color: #005476;
    color: #fff;
}

.startCycleBtton {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    padding: 5px;
    width: 140px;
    background-color: #0B6F9D;
    color: #fff;
}

.dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
}

.greendot {
    height: 12px;
    width: 12px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}

.yellowdot {
    height: 12px;
    width: 12px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
}

.orangedot {
    height: 12px;
    width: 12px;
    background-color: orange;
    border-radius: 50%;
    display: inline-block;
}

.reddot {
    height: 12px;
    width: 12px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}

.blink_me {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    80% {
        opacity: 0.2;
    }
}