.fileterButton {
    padding: 5px;
    width: 100px;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background-color: #0B6F9D;
}

.resetButton {
    padding: 5px;
    width: 100px;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background-color: black;
}

.startReturnableDate {
    width: 300px;
}

.filterbtn {
    font-size: 18px;
    font-weight: 400;
    color: black;
}

.planDateTimeInput {
    width: 100%;
    height: 42px;
    padding: 10px;
}

.startPlanBtton {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    padding: 5px;
    width: 150px;
    background-color: #0B6F9D;
    color: #fff;
}

.returnableTab {
    padding: 7px;
    height: 100%;
    width: 100px;
    margin-right: 10px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    color: black;
    background-color: #cad2d3;
}

.returnableTabs .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 0px;
    padding: 0;
}

.returnableTabs .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    background: #005476;
    margin: 0em 0.5em 0em 0em;
    color: black !important;
    border-radius: 5px 5px 0px 0px;
}

.returnableTabs .react-tabs__tab--selected {
    background: #005476;
    border-color: #aaa;
    color: #fff;
    border-radius: 10px 10px 0 0;
    border: none;
    pointer-events: none;
}

.returnableTabs .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 0px;
    padding: 0;
    display: grid !important;
}

.hoverTab:hover {
    background-color: #005476;
    color: #fff;
}

