.filterDropdownMenu .MuiPopover-paper {
    overflow-y: visible;
    overflow-x: visible;
}

/* .customerDropdownIcon .css-1q60rmi-MuiAutocomplete-endAdornment {
    top: calc(50% - 25px);
} */

.assetCycleThead th {
    background-color: #0B6F9D;
    color: #fff !important;
    font-weight: bold;
}

