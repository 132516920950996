.activeBtn{
    background: rgb(11, 111, 157) !important;
        color: rgb(255, 255, 255)!important;
        height: 33px!important;
        width: 80px!important;
        border-radius: 5px!important;
        text-transform: none!important;
}
.InactiveBtn{
    background: rgb(215, 219, 221)!important;
        color: rgb(0, 0, 0)!important;
        height: 33px!important;
        width: 80px!important;
        border-radius: 5px!important;
        text-transform: none!important;
}