.actionBtn{
    cursor: pointer;
    color:#6A7B8E ;
}
#assetCardHeader{
    padding-bottom:1em
}
.cardTitle{
    float: left;
        text-align: left;
        margin: auto;
}
.cardAction{
    float: right;
        text-align: right;
        margin: auto;
}