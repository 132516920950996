.supplierCard {
    height: 590px;
}

.supplierCard-1 {
    height: 590px;
    margin: 0;
}

.viewSupCard {
    height: 430px;
    margin: 0;
}

.verifyGstnBtn {
    height: 43px !important;
    background: green !important;
    color: #fff !important;
    width: 44px !important;
    padding: 3px !important;
    margin-left: 0em !important;
    margin-right: -0.6em !important;
    cursor: pointer;
    margin-top: -0.2em;
}


@media only screen and (min-width: 200px) and (max-width: 670px) {
    .supplierCard {
        height: 100%;
    }

    .supplierCard-1 {
        height: 100%;
        margin-top: 15px;
    }

    .viewSupCard {
        height: 100%;
        margin-top: 15px;
    }
}