#dropdown-menu .MuiAvatar-circular {
  margin: 0 !important;
  height: 40px !important;
  width: 40px !important;
}

#dropdown-menu .MuiMenu-root .MuiPaper-root {
  min-width: 12rem !important;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  80% {
    opacity: 0.5;
  }
}