.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 0.1876em !important;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
  top: none !important;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 8px;
}

.orggstnBtn {
  height: 37px !important;
  background: green !important;
  color: #fff !important;
  width: 40px !important;
  padding: 3px !important;
  margin-left: 0em !important;
  margin-right: -0.6em !important;
  cursor: pointer;
  /* margin-top: -0.25em; */
}

.tableThead th {
  background-color: #0B6F9D;
  color: #fff !important;
  font-weight: 700 !important;
}