.card-text {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 21px;
    font-weight: lighter;
    letter-spacing: -0.02em;
    color: #566A7F;
}

.confirmationCardHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #566A7F;
}

.cardBox {
    width: 750px;
    position: fixed;
    top: 47%;   
    left: 50%;
    transform: translate(-50%, -50%);
}

.button {
    font-family: 'Poppins';
    font-style: normal;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: 250px;
    height: 35px;
    background: #0B6F9D;
    border-radius: 5px;
    color: #FFFFFF;
    border: none;
}   

.emailCard {
    align-items: center;
    width: 1000px;
    height: 550px;
    margin-left: -165px;
    background: #FFFFFF;
}

.textBox {
    position: absolute;
    left: 8%;
    right: 8%;
    top: 18%;
    bottom: 42.88%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 140%;
    /* or 56px */
    text-align: center;
    letter-spacing: -0.02em;
    color: #566A7F;
}

.resendEmail {
    padding-top: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    letter-spacing: -0.02em;    
    text-decoration-line: underline;
    color: #0671E0;
}