.toast-success-container {
  color: #fff !important;
  border-radius: 8px !important;
  background: #149436 !important;
  border: 1px solid #149436 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

.toast-success-container-after::after {
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #149436;
}

.toast-error-container {
  color: #fff !important;
  border-radius: 8px !important;
  background: #bc2525 !important;
  border: 1px solid #bc2525 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-error-container-after {
  overflow: hidden;
  position: relative;
}

.toast-error-container-after::after {
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background: #bc2525 !important;
}

.toast-warn-container {
  color: #fff !important;
  border-radius: 8px !important;
  background: #7c7b18 !important;
  border: 1px solid #746c0a !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-warn-container-after {
  overflow: hidden;
  position: relative;
}

.toast-warn-container-after::after {
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #7c7b18;
}