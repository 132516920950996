.navOpen {
    margin-left: 262px;
}

.navClose {
    margin-left: 100px;
}

.layoutOpen {
    margin-left: 250px;
}

.layoutClose {
    margin-left: 100px;
}

@media (max-width: 1180px) {
    .navOpen {
        margin-left: 0px;
        /* z-index: 1; */
    }

    .navClose {
        margin-left: 0px;
    }

    .layoutOpen {
        margin-left: 0px;
    }

    .layoutClose {
        margin-left: 0px;
    }
}

@media (max-width: 991.98px) {
    .layout-navbar.navbar-detached {
        width: 100% !important;
    }
}