#signupFormLabel {
  float: left;
  /* margin-bottom: 0rem; */
}

#signupTermsConditionsLabel {
  float: left;
}

#signupSubheading {
  text-align: left;
}

#signupTitle {
  text-transform: none;
}

#checkBox {
  padding-left: 2.5em;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

#formAuthentication .MuiFormHelperText-root {
  position: absolute;
  bottom: -1.6rem;
}

#forgot-password-link #formAuthentication .MuiFormHelperText-root {
  bottom: -1.1rem;
}

.termsPolicyBody {
  max-height: 300px;
  overflow-x: auto;
}

.form-password-toggle {
  align-self: flex-start;
}

.form-password-toggle .MuiFormHelperText-root {
  position: initial !important;
}

.leftBackground {
  background: #0b6f9d;
}

.signupHeding {
  /* position: absolute; */
  width: 285px;
  height: 48px;
  margin-left: -10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
}

.signupHeding-1 {
  width: 170px;
  height: 48px;
  margin-left: -10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
}

.signupButton {
  background-color: #0b6f9d;
  width: 400px;
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  align-items: center;
  border: none;
  border-radius: 5px;
}

.logoSignupBackground {
  border-radius: 70%;
  background-color: #fff;
  width: 300px;
  height: 300px;
  /* margin-top: -150px; */
  /* margin-left: 220px; */
  align-items: center;
}

.logoSignupImage {
  /* width: 90%; */
  height: 220px;
  margin-top: 45px;
  /* margin-left: 1px; */
}

.step {
  display: flex;
  flex-direction: row;
  inline-size: 250px;
  cursor: pointer;
  /* font-size: 22px; */
}

.bxc {
  margin-top: 8px;
  font-size: 35px;
  padding: 8px;
  margin-right: 14px;
  border-radius: 50%;
  background-color: lightblue;
}

.bs-stepper-title {
  font-size: 22px;
}

.line {
  font-size: 32px;
  padding: 5px;
}

.bx-chevron-right {
  font-size: 32px;
}

.nextSignUp {
  font-size: 18px;
  padding: 5px;
  align-items: end;
  padding-left: 30px;
  padding-right: 30px;
  /* margin-left: 430px; */
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: #0b6f9d;
}

.backSignUp {
  font-size: 18px;
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: lightslategray;
}

.submitSignup {
  font-size: 18px;
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: lightslategray;
}

@media (max-width: 950px) {
  .logoSignupBackground {
    border-radius: 50%;
    background-color: #fff;
    width: 180px;
    height: 180px;
    margin-top: 50px;
    margin-left: 60px;
    align-items: center;
  }

  .logoSignupImage {
    /* width: 100%; */
    height: 120px;
    margin-top: 35px;
    /* margin-left: 1px; */
  }

  .logoHeding {
    margin-top: 30px;
    margin-left: 8px;
    font-size: 45px;
  }

  .nextSignUp {
    margin-left: -450px;
  }
}
