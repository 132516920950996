#initiateBtn {
  float: right;
  height: 100%;
  align-items: center;
}

.initiateKycRow {
  margin: 1em 0em;
}

.buyDaoTab {
  text-align: left;
}

.buyDaoTab.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #005476;
  color: #fff;
  margin: 5px;
}

.buyDaoTab.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.buyDaoTab.tab-content {
  background: transparent;
  padding: 0px;
  margin: 0em 0em 0em 0.4em;
}

.buyDaoTab .react-tabs__tab-list {
  border-bottom: none;
  margin: 0 0 0px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.buyDaoTab .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;

  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px 28px;
  cursor: pointer;
  background: #d5d8dc;
  color: #000;
  border-radius: 5px 5px 5px 5px;
}

.buyDaoTab .react-tabs__tab-panel .form-label {
  font-weight: 400;
  font-size: 1rem;
  color: #566a7f;
}

.buyDaoTab .react-tabs__tab img {
  margin-right: 0.5rem;
}

.buyDaoTab .react-tabs__tab--selected {
  background: #ffab00 !important;
  border-color: #ffab00;
  color: #fff !important;
  border-radius: 5px 5px 5px 5px;
}

.buyDaoTab .react-tabs__tab--selected img {
  filter: brightness(0) invert(1);
  margin-right: 0.5rem;
}

.dashboard .MuiOutlinedInput-input {
  padding: 14.5px 14px;
}

#dashboard .account-select {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

#dashboard .account-select .account {
  width: 16rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#dashboard h5 {
  margin: 0;
}

#dashboard .row {
  align-items: center;
}

#dashboard .react-tabs__tab-panel .btn {
  align-items: center;
  transform-origin: top left;
  transform: scale(1.3);
  margin-top: 1.2rem;
}

.receipt-modal .header {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.receipt-modal .details {
  padding-top: 1rem;
}

.receipt-modal .details .row {
  margin: 0.5rem 0;
}

.receipt-modal .details .label {
  font-weight: 700;
}

.receipt-modal .details .btn {
  margin-top: 1rem;
}

#dashboard .balance-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

#dashboard .balance {
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.4px;
  color: #0671e0;
}

.dashboardCardText {
  text-align: left;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  align-items: center !important;
}

.dateTimeInput {
  height: 35px;
  width: 200px;
}

.btnDate {
  width: 80px;
  color: #fff;
  align-self: left;
  background-color: #0B6F9D;
  border: none;
}

@media screen and (max-width: 768px) {
  #dashboard .account-select {
    margin-top: 1rem;
  }

  .react-tabs__tab-panel .btn {
    font-size: 1.1rem;
    transform: scale(1);
  }

  .receipt-modal .header {
    display: block;
    text-align: center;
  }
}