.main-banner .left-content h4 em {
    color: #03a4ed;
    font-style: normal;
}

.main-banner .left-content h4 span {
    color: #fe3f40;
}

.main-banner .left-content h4 {
    font-size: 36px;
    font-weight: 700;
    color: #2a2a2a;
    line-height: 61px;
}

#evenBoxStyle {
    background-color: #e6e5ec;
    color: #000 !important;
}

#evenBoxStyle p {
    color: #000 !important;
}

#oddBoxStyle {
    background-color: #ffecec;
}

#oddBoxStyle p {
    color: #000 !important;
}

#services p {
    text-align: left !important;
    line-height: 28px;
    font-size: 18px;
}

#services h3 {
    text-align: left !important;
    font-weight: bold;
    color: royalblue;
}

.faqHeading .accordion__button {
    text-align: left;
    padding: 1em;
    background: #E5E7E9;
    color: #000;
    margin: 1em 0em;
}

.faqPanel {
    padding: 1em;
    text-align: left;
    color: #000;

}

.accordion__button:hover {
    color: #000 !important;
}

.faqTitle {
    color: #000;
    text-align: left;
    padding: 1em 0em;
}

.aboutUsTitle {
    color: #000;
    text-align: left;
    padding: 0em 0em;
    margin-left: 2.8em;
}

.aboutUsp {
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    margin-left: 4em;
}

#ourFeatures p {
    font-size: 15px;
    line-height: 25px;
    color: #2a2a2a;
    padding: 0.5em;
    margin-top: 1em;
}

#ourFeatures h4 {
    color: #000;
    font-weight: bolder;
    text-transform: capitalize;
}

.ourFeaturesTitle {
    color: #000;
    text-align: left;
    padding: 0.5em;

}

#contact .MuiOutlinedInput-input {
    padding: 22.5px 14px;
}

#contactForm {
    background: #fff;
    border-radius: 5px;
    padding: 3em;
}

.accordion__item+.accordion__item {
    border-top: none !important
}

.our-portfolio .showed-content {
    z-index: 0;
}

.navHeader {
    background-color: #0B6F9D;
    color: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    /* height: 80px; */
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
}

@media (max-width: 600px) {
    .header-area {
        background-color: #0B6F9D;
    }

    .aboutUsTitle {
        margin-left: 0em;
    }

    .foatingActionBtn {
        margin: 0px !important;
    }

    .aboutUsp {
        margin: 0px !important;
    }
}