#modal
  .close-button {
    position: absolute;
    right: -15px;
    top: -14px;
    padding: 0.5rem;
    background: white;
    border-radius: 17px;
    box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%);
  }


.confirm-modal {
  max-width: 40rem !important;
  word-break: break-word;
}
 .confirm-modal .action-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    flex-wrap: wrap;
    float: right;
  }


@media screen and (max-width: 530px) {
  .confirm-modal .action-buttons .btn {
    width: 100%;
  }
}