#assetFormCard {
  position: relative !important;
  height: 100% !important;
  padding: 0.5em;
  /* margin: 0em 0.5em; */
}

#AssetCol {
  text-align: left;
  margin: auto;
}

.newPropertiesBtn {
  border-Radius: 25%;
  background: #867e7e;
  color: white;
  font-Size: x-large !important;
  width: 30px !important;
  min-Height: 30px;
  padding: 2px;
  cursor: pointer;

  margin-Right: 3px;
}

#assetFormLabel {
  text-align: left !important;
}

#headerLabel {
  font-weight: bold !important;
  color: #252733 !important;
}

.assetPropertyTable {
  background: #0B6F9D;
}

.assetPropertyTable th {
  color: #fff !important;
}

#modalTextFiled {
  margin-left: auto !important;
  margin-right: auto !important;
}

#modalFormLabel {
  margin: 0.5em 0em;
  padding-left: 0em;
}

.assetTabRow .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #cad2d3;
  color: #fff;
  margin: 5px;
}

.assetTabRow .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #005476;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: none;
}

.assetTabRow .tab-content {
  background: transparent;
  padding: 0px;
  margin: 0em 0em 0em 0.4em;
}

.assetTabRow .card {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px transparent;
  border: 0;
  margin-bottom: 20px;
}

.assetTab .react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 0px;
  padding: 0;
  display: flex;
}

.assetTab .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  background: #cad2d3;
  margin: 0em 0.8em 0em 0em;
  color: #000;
  border-radius: 5px 5px 0px 0px;
}

.assetTab .react-tabs__tab--selected {
  background: #005476 !important;
  border-color: 005476;
  color: #fff !important;
  border-radius: 5px 5px 0 0;
  padding: 0.5em 2em
}

.MuiTypography-h6 {
  font-weight: 600 !important;
  color: #252733 !important;
  font-size: 1.12rem !important;
}

.MuiToolbar-gutters {
  padding-left: 12px !important;
}
