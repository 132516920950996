.cardSize {
  height: 520px;
  margin-top: 0;
}
.cardSize-1 {
  height: 520px;
  margin-top: 0;
}
.ybutton {
background-color: #0B6F9D;
border: none;
color: #fff;
}
@media only screen and (min-width: 200px) and (max-width: 670px) {
  .cardSize {
    height: 100%; 
  }
  .cardSize-1 {
    height: 100%; 
    margin-top: 20px;
  }
} 