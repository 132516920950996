.goButton {
    background-color: #0B6F9D;
    width: 400px;
    height: 40px;
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'Poppins';
    font-style: normal;
    align-items: center;
    border: none;
    border-radius: 5px;
}

@media (max-width: 800px) {
    .goButton {
        width: 100%;
    }
}